<template>
  <div id="NewsCards">
    <h1>Cards</h1>
  </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>